import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { IconSpinner } from '../../components';
import { fetchListingId } from '../ListingPage/ListingPage.duck';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import css from './FetchListing.module.css';
import ListingPage from '../ListingPage/ListingPage';
function FetchListing(props) {
  const { params, fetchListingIdData, fetchListingInProgress, fetchListingError } = props;
  // console.log('fetchListingError', fetchListingError);
  const intl = useIntl();
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [listingId, setListingId] = useState(null);
  useEffect(() => {
    return () => {
      setListingId(null);
    };
  }, []);
  useEffect(() => {
    (async () => {
      try {
        setloading(true);
        const res = await fetchListingIdData(params);
        const id = res?.uuid;
        // console.log('id', id);
        if (id) {
          // history.push(`/l/${id}`);
          setListingId(id);
        }
        setloading(false);
      } catch (err) {
        // console.log('err', err);
        setloading(false);
      }
    })();
  }, []);
  if (fetchListingError) {
    return <NotFoundPage />;
  }
  return listingId ? (
    <ListingPage params={{ id: listingId }} />
  ) : (
    <div className={css.pageWrapper}>
      {loading ? (
        <div className={css.loading}>
          <IconSpinner />
        </div>
      ) : null}
    </div>
  );
}
const mapStateToProps = state => {
  const { fetchListingInProgress, fetchListingError } = state.ListingPage;
  return {
    fetchListingInProgress,
    fetchListingError,
  };
};
const mapDispatchToProps = dispatch => ({
  fetchListingIdData: params => dispatch(fetchListingId(params)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(FetchListing);
